import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7db0c833 = () => interopDefault(import('../../baserow/web-frontend/modules/core/pages/login.vue' /* webpackChunkName: "" */))
const _f67ba984 = () => interopDefault(import('../../baserow/web-frontend/modules/core/pages/signup.vue' /* webpackChunkName: "" */))
const _bd69aff8 = () => interopDefault(import('../../baserow/web-frontend/modules/core/pages/forgotPassword.vue' /* webpackChunkName: "" */))
const _2bd7cb54 = () => interopDefault(import('../../baserow/web-frontend/modules/core/pages/resetPassword.vue' /* webpackChunkName: "" */))
const _5a1ea077 = () => interopDefault(import('../../baserow/web-frontend/modules/core/pages/verifyEmailAddress.vue' /* webpackChunkName: "" */))
const _62798cde = () => interopDefault(import('../../baserow/web-frontend/modules/core/pages/dashboard.vue' /* webpackChunkName: "" */))
const _1e78b342 = () => interopDefault(import('../../baserow/web-frontend/modules/core/pages/workspace.vue' /* webpackChunkName: "" */))
const _72d4ae50 = () => interopDefault(import('../../baserow/web-frontend/modules/core/pages/workspaceInvitation.vue' /* webpackChunkName: "" */))
const _6815d189 = () => interopDefault(import('../../baserow/web-frontend/modules/core/pages/admin/settings.vue' /* webpackChunkName: "" */))
const _ee99e27c = () => interopDefault(import('../../baserow/web-frontend/modules/core/pages/admin/health.vue' /* webpackChunkName: "" */))
const _54bdc571 = () => interopDefault(import('../../baserow/web-frontend/modules/core/pages/styleGuide.vue' /* webpackChunkName: "" */))
const _3c2f9ac5 = () => interopDefault(import('../../baserow/web-frontend/modules/core/pages/_health.vue' /* webpackChunkName: "" */))
const _03464d49 = () => interopDefault(import('../../baserow/web-frontend/modules/core/pages/settings.vue' /* webpackChunkName: "" */))
const _6146055a = () => interopDefault(import('../../baserow/web-frontend/modules/core/pages/settings/members.vue' /* webpackChunkName: "" */))
const _233dc644 = () => interopDefault(import('../../baserow/web-frontend/modules/core/pages/settings/invites.vue' /* webpackChunkName: "" */))
const _ec1efdb0 = () => interopDefault(import('../../baserow/enterprise/web-frontend/modules/baserow_enterprise/pages/settings/teams.vue' /* webpackChunkName: "" */))
const _2409340d = () => interopDefault(import('../../baserow/web-frontend/modules/core/pages/notificationRedirect.vue' /* webpackChunkName: "" */))
const _f503807e = () => interopDefault(import('../../baserow/web-frontend/modules/core/pages/onboarding.vue' /* webpackChunkName: "" */))
const _5dc2e574 = () => interopDefault(import('../../baserow/web-frontend/modules/database/pages/table.vue' /* webpackChunkName: "" */))

const _69136c0a = () => interopDefault(import('../../baserow/web-frontend/modules/database/pages/APIDocs.vue' /* webpackChunkName: "" */))
const _c2a131d4 = () => interopDefault(import('../../baserow/web-frontend/modules/database/pages/APIDocsDatabase.vue' /* webpackChunkName: "" */))
const _e34194e4 = () => interopDefault(import('../../baserow/web-frontend/modules/database/pages/form.vue' /* webpackChunkName: "" */))
const _b6008a10 = () => interopDefault(import('../../baserow/web-frontend/modules/database/pages/publicView.vue' /* webpackChunkName: "" */))
const _60341901 = () => interopDefault(import('../../baserow/web-frontend/modules/database/pages/publicViewLogin.vue' /* webpackChunkName: "" */))
const _27b82c80 = () => interopDefault(import('../../baserow/web-frontend/modules/builder/pages/pageEditor.vue' /* webpackChunkName: "" */))
const _1fc6cf7c = () => interopDefault(import('../../baserow/web-frontend/modules/builder/pages/publicPage.vue' /* webpackChunkName: "" */))
const _29b5a119 = () => interopDefault(import('../../baserow/web-frontend/modules/dashboard/pages/dashboard.vue' /* webpackChunkName: "" */))
const _39863ebe = () => interopDefault(import('../../baserow/premium/web-frontend/modules/baserow_premium/pages/admin/dashboard.vue' /* webpackChunkName: "" */))
const _5f63a132 = () => interopDefault(import('../../baserow/premium/web-frontend/modules/baserow_premium/pages/admin/users.vue' /* webpackChunkName: "" */))
const _35e650c4 = () => interopDefault(import('../../baserow/premium/web-frontend/modules/baserow_premium/pages/admin/workspaces.vue' /* webpackChunkName: "" */))
const _223b7818 = () => interopDefault(import('../../baserow/premium/web-frontend/modules/baserow_premium/pages/admin/licenses.vue' /* webpackChunkName: "" */))
const _4b4ef66b = () => interopDefault(import('../../baserow/premium/web-frontend/modules/baserow_premium/pages/admin/license.vue' /* webpackChunkName: "" */))
const _048e668d = () => interopDefault(import('@baserow/modules/database/pages/publicView.vue' /* webpackChunkName: "" */))
const _0f34dd28 = () => interopDefault(import('../../baserow/enterprise/web-frontend/modules/baserow_enterprise/pages/login/loginWithSAML.vue' /* webpackChunkName: "" */))
const _189d203a = () => interopDefault(import('../../baserow/enterprise/web-frontend/modules/baserow_enterprise/pages/login/loginError.vue' /* webpackChunkName: "" */))
const _5181abac = () => interopDefault(import('../../baserow/enterprise/web-frontend/modules/baserow_enterprise/pages/admin/authProviders.vue' /* webpackChunkName: "" */))
const _2044bec7 = () => interopDefault(import('../../baserow/enterprise/web-frontend/modules/baserow_enterprise/pages/auditLog.vue' /* webpackChunkName: "" */))
const _19770622 = () => interopDefault(import('../plugins/saas/web-frontend/pages/cmsPage.vue' /* webpackChunkName: "" */))
const _0932cd9b = () => interopDefault(import('../plugins/saas/web-frontend/pages/licenseAuthority.vue' /* webpackChunkName: "" */))
const _bab40532 = () => interopDefault(import('../plugins/saas/web-frontend/pages/devDocs.vue' /* webpackChunkName: "" */))
const _e7a8fcd2 = () => interopDefault(import('../plugins/saas/web-frontend/pages/contact.vue' /* webpackChunkName: "" */))
const _e7712436 = () => interopDefault(import('../plugins/saas/web-frontend/pages/contactSales.vue' /* webpackChunkName: "" */))
const _3950e602 = () => interopDefault(import('../plugins/saas/web-frontend/pages/privacy.vue' /* webpackChunkName: "" */))
const _c2127184 = () => interopDefault(import('../plugins/saas/web-frontend/pages/terms.vue' /* webpackChunkName: "" */))
const _69988522 = () => interopDefault(import('../plugins/saas/web-frontend/pages/jobs.vue' /* webpackChunkName: "" */))
const _5d1875e6 = () => interopDefault(import('../plugins/saas/web-frontend/pages/faq.vue' /* webpackChunkName: "" */))
const _32ca8b86 = () => interopDefault(import('../plugins/saas/web-frontend/pages/pricing.vue' /* webpackChunkName: "" */))
const _7493bbbe = () => interopDefault(import('../plugins/saas/web-frontend/pages/templateListing.vue' /* webpackChunkName: "" */))
const _e4cda278 = () => interopDefault(import('../plugins/saas/web-frontend/pages/templateDetail.vue' /* webpackChunkName: "" */))
const _c63367bc = () => interopDefault(import('../plugins/saas/web-frontend/pages/videoListing.vue' /* webpackChunkName: "" */))
const _18ab40a3 = () => interopDefault(import('../plugins/saas/web-frontend/pages/videoDetail.vue' /* webpackChunkName: "" */))
const _87c5ab98 = () => interopDefault(import('../plugins/saas/web-frontend/pages/job.vue' /* webpackChunkName: "" */))
const _02fd8abb = () => interopDefault(import('../plugins/saas/web-frontend/pages/blog.vue' /* webpackChunkName: "" */))
const _d7744f56 = () => interopDefault(import('../plugins/saas/web-frontend/pages/blog/blogPage.vue' /* webpackChunkName: "" */))
const _95ad9582 = () => interopDefault(import('../plugins/saas/web-frontend/pages/userDocs.vue' /* webpackChunkName: "" */))
const _7b236514 = () => interopDefault(import('../plugins/saas/web-frontend/pages/subscriptions/subscriptions.vue' /* webpackChunkName: "" */))
const _9430a80a = () => interopDefault(import('../plugins/saas/web-frontend/pages/subscriptions/newSubscription.vue' /* webpackChunkName: "" */))
const _777779ef = () => interopDefault(import('../plugins/saas/web-frontend/pages/subscriptions/subscription.vue' /* webpackChunkName: "" */))
const _2a094520 = () => interopDefault(import('../plugins/saas/web-frontend/pages/community.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login",
    component: _7db0c833,
    name: "login"
  }, {
    path: "/signup",
    component: _f67ba984,
    name: "signup"
  }, {
    path: "/forgot-password",
    component: _bd69aff8,
    name: "forgot-password"
  }, {
    path: "/reset-password/:token",
    component: _2bd7cb54,
    name: "reset-password"
  }, {
    path: "/verify-email-address/:token",
    component: _5a1ea077,
    name: "verify-email-address"
  }, {
    path: "/dashboard",
    component: _62798cde,
    name: "dashboard"
  }, {
    path: "/workspace/:workspaceId",
    component: _1e78b342,
    name: "workspace"
  }, {
    path: "/workspace-invitation/:token",
    component: _72d4ae50,
    name: "workspace-invitation"
  }, {
    path: "/admin/settings",
    component: _6815d189,
    name: "admin-settings"
  }, {
    path: "/admin/health",
    component: _ee99e27c,
    name: "admin-health"
  }, {
    path: "/style-guide",
    component: _54bdc571,
    name: "style-guide"
  }, {
    path: "/_health",
    component: _3c2f9ac5,
    name: "health-check"
  }, {
    path: "/workspace/:workspaceId/settings",
    component: _03464d49,
    name: "settings",
    children: [{
      path: "members",
      component: _6146055a,
      name: "settings-members"
    }, {
      path: "invites",
      component: _233dc644,
      name: "settings-invites"
    }, {
      path: "teams",
      component: _ec1efdb0,
      name: "settings-teams"
    }]
  }, {
    path: "/notification/:workspaceId/:notificationId",
    component: _2409340d,
    name: "notification-redirect"
  }, {
    path: "/onboarding",
    component: _f503807e,
    name: "onboarding"
  }, {
    path: "/database/:databaseId/table/:tableId/:viewId?",
    component: _5dc2e574,
    props: function(route) {
    // @TODO figure out why the route param is empty on the server side.
    const p = { ...route.params };
    p.databaseId = parseInt(p.databaseId);
    p.tableId = parseInt(p.tableId);
    p.viewId = p.viewId ? parseInt(p.viewId) : null;
    return p;
  },
    name: "database-table",
    children: [{
      path: "row/:rowId",
      name: "database-table-row"
    }]
  }, {
    path: "/api/docs",
    redirect: "/api-docs"
  }, {
    path: "/api/docs/database/:databaseId",
    redirect: "/api-docs/database/:databaseId"
  }, {
    path: "/api-docs",
    component: _69136c0a,
    alias: "/api/docs",
    name: "database-api-docs"
  }, {
    path: "/api-docs/database/:databaseId",
    component: _c2a131d4,
    name: "database-api-docs-detail"
  }, {
    path: "/form/:slug",
    component: _e34194e4,
    name: "database-table-form"
  }, {
    path: "/public/grid/:slug",
    component: _b6008a10,
    name: "database-public-grid-view"
  }, {
    path: "/public/gallery/:slug",
    component: _b6008a10,
    name: "database-public-gallery-view"
  }, {
    path: "/public/auth/:slug",
    component: _60341901,
    name: "database-public-view-auth"
  }, {
    path: "/builder/:builderId/page/:pageId",
    component: _27b82c80,
    props: function(route) {
    const p = { ...route.params };
    p.builderId = parseInt(p.builderId);
    p.pageId = parseInt(p.pageId);
    return p;
  },
    name: "builder-page"
  }, {
    path: "*",
    component: _1fc6cf7c,
    meta: {"publishedBuilderRoute":true},
    name: "application-builder-page"
  }, {
    path: "/_health",
    component: _3c2f9ac5,
    meta: {"publishedBuilderRoute":true},
    name: "health-check"
  }, {
    path: "/builder/:builderId/preview*",
    component: _1fc6cf7c,
    name: "application-builder-page"
  }, {
    path: "/dashboard/:dashboardId",
    component: _29b5a119,
    props: function(route) {
    const p = { ...route.params };
    p.dashboardId = parseInt(p.dashboardId);
    return p;
  },
    name: "dashboard-application"
  }, {
    path: "/admin/dashboard",
    component: _39863ebe,
    name: "admin-dashboard"
  }, {
    path: "/admin/users",
    component: _5f63a132,
    name: "admin-users"
  }, {
    path: "/admin/workspaces",
    component: _35e650c4,
    name: "admin-workspaces"
  }, {
    path: "/admin/licenses",
    component: _223b7818,
    name: "admin-licenses"
  }, {
    path: "/admin/license/:id",
    component: _4b4ef66b,
    name: "admin-license"
  }, {
    path: "/public/kanban/:slug",
    component: _048e668d,
    name: "database-public-kanban-view"
  }, {
    path: "/public/calendar/:slug",
    component: _048e668d,
    name: "database-public-calendar-view"
  }, {
    path: "/public/timeline/:slug",
    component: _048e668d,
    name: "database-public-timeline-view"
  }, {
    path: "/login/saml",
    component: _0f34dd28,
    name: "login-saml"
  }, {
    path: "/login/error",
    component: _189d203a,
    name: "login-error"
  }, {
    path: "/admin/auth-providers",
    component: _5181abac,
    name: "admin-auth-providers"
  }, {
    path: "/admin/audit-log",
    component: _2044bec7,
    name: "admin-audit-log"
  }, {
    path: "/workspace/:workspaceId/audit-log",
    component: _2044bec7,
    name: "workspace-audit-log"
  }, {
    path: "",
    component: _19770622,
    name: "index"
  }, {
    path: "/admin/license-authority",
    component: _0932cd9b,
    name: "admin-license-authority"
  }, {
    path: "/docs/:path(.*)?",
    component: _bab40532,
    name: "dev-docs"
  }, {
    path: "/contact",
    component: _e7a8fcd2,
    name: "contact"
  }, {
    path: "/contact-sales",
    component: _e7712436,
    name: "contact-sales"
  }, {
    path: "/privacy-policy",
    component: _3950e602,
    name: "privacy"
  }, {
    path: "/terms-and-conditions",
    component: _c2127184,
    name: "terms"
  }, {
    path: "/terms/subscription",
    redirect: {"name":"terms"}
  }, {
    path: "/jobs",
    component: _69988522,
    name: "jobs"
  }, {
    path: "/faq",
    component: _5d1875e6,
    name: "faq"
  }, {
    path: "/about-us",
    component: _19770622,
    name: "about-us"
  }, {
    path: "/pricing",
    component: _32ca8b86,
    name: "pricing"
  }, {
    path: "/templates",
    component: _7493bbbe,
    name: "templates"
  }, {
    path: "/templates/:slug",
    component: _e4cda278,
    name: "template-detail"
  }, {
    path: "/videos",
    component: _c63367bc,
    name: "videos"
  }, {
    path: "/videos/:slug",
    component: _18ab40a3,
    name: "video-detail"
  }, {
    path: "/get-license/:instanceId/",
    redirect: {"name":"pricing","query":{"version":"self-hosted"}}
  }, {
    path: "/jobs/:slug([a-zA-Z0-9 _-]+)",
    component: _87c5ab98,
    name: "job"
  }, {
    path: "/blog/",
    component: _02fd8abb,
    name: "blog"
  }, {
    path: "/blog/category/:category([a-zA-Z0-9 _-]+)/:page([0-9]+)?",
    component: _02fd8abb,
    name: "blog-filtered"
  }, {
    path: "/blog/:blogPostSlug([a-zA-Z0-9 _-]+)",
    component: _d7744f56,
    name: "blog-post-page"
  }, {
    path: "/user-docs/:slug(.*)?",
    component: _95ad9582,
    name: "user-docs"
  }, {
    path: "/subscriptions",
    component: _7b236514,
    name: "subscriptions"
  }, {
    path: "/subscriptions/new/",
    component: _9430a80a,
    name: "new-subscription"
  }, {
    path: "/subscriptions/:subscriptionId/",
    component: _777779ef,
    name: "subscription"
  }, {
    path: "/community",
    component: _2a094520,
    name: "community"
  }, {
    path: "/product/:product(.*)?",
    component: _19770622,
    name: "product"
  }, {
    path: "/departments/:department(.*)?",
    component: _19770622,
    name: "department"
  }, {
    path: "/industries/:industry(.*)?",
    component: _19770622,
    name: "industry"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
