var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    [`repeat-element--orientation-${_vm.element.orientation}`]: true,
  }},[(_vm.elementContent.length > 0)?[_c('div',{staticClass:"repeat-element__repeated-elements",style:(_vm.repeatedElementsStyles)},_vm._l((_vm.elementContent),function(content,index){return _c('div',{key:content.id},[(_vm.children.length > 0)?[_vm._l((_vm.children),function(child){return [(index === 0 && _vm.isEditMode)?_c('ElementPreview',{key:`${child.id}-${index}`,attrs:{"element":child,"application-context-additions":{
                recordIndexPath: [
                  ..._vm.applicationContext.recordIndexPath,
                  index,
                ],
              }},on:{"move":function($event){return _vm.moveElement(child, $event)}}}):_c('PageElement',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCollapsed),expression:"!isCollapsed"}],key:`${child.id}_${index}`,class:{
                'repeat-element__preview': index > 0 && _vm.isEditMode,
              },attrs:{"element":child,"force-mode":_vm.isEditMode ? 'public' : _vm.mode,"application-context-additions":{
                recordIndexPath: [
                  ..._vm.applicationContext.recordIndexPath,
                  index,
                ],
              }}})]})]:_vm._e()],2)}),0),_vm._v(" "),(_vm.children.length === 0 && _vm.isEditMode)?[_c('AddElementZone',{attrs:{"disabled":_vm.elementIsInError,"tooltip":_vm.addElementErrorTooltipMessage},on:{"add-element":_vm.showAddElementModal}}),_vm._v(" "),_c('AddElementModal',{ref:"addElementModal",attrs:{"page":_vm.page,"element-types-allowed":_vm.elementType.childElementTypes(_vm.page, _vm.element)}})]:_vm._e()]:[(_vm.children.length === 0 && _vm.isEditMode)?[_c('AddElementZone',{attrs:{"disabled":_vm.elementIsInError,"tooltip":_vm.addElementErrorTooltipMessage},on:{"add-element":_vm.showAddElementModal}}),_vm._v(" "),_c('AddElementModal',{ref:"addElementModal",attrs:{"page":_vm.page,"element-types-allowed":_vm.elementType.childElementTypes(_vm.page, _vm.element)}})]:(_vm.isEditMode)?[(_vm.contentLoading)?_c('div',{staticClass:"loading"}):_vm._l((_vm.children),function(child){return _c('ElementPreview',{key:child.id,attrs:{"element":child},on:{"move":function($event){return _vm.moveElement(child, $event)}}})})]:_vm._e()],_vm._v(" "),_c('div',{staticClass:"repeat-element__footer"},[(_vm.hasMorePage && _vm.children.length > 0)?_c('ABButton',{style:(_vm.getStyleOverride('button')),attrs:{"disabled":_vm.contentLoading || !_vm.contentFetchEnabled,"loading":_vm.contentLoading},on:{"click":function($event){return _vm.loadMore()}}},[_vm._v("\n      "+_vm._s(_vm.resolvedButtonLoadMoreLabel || _vm.$t('repeatElement.showMore'))+"\n    ")]):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }