var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-element"},[_c('ABTable',{style:(_vm.getStyleOverride('table')),attrs:{"fields":_vm.fields,"rows":_vm.rows,"content-loading":_vm.contentLoading,"orientation":_vm.orientation},scopedSlots:_vm._u([{key:"cell-content",fn:function({ rowIndex, field, value }){return [_c('td',{key:field.id,staticClass:"ab-table__cell",style:({
          '--force-self-alignment': 'auto',
          ..._vm.fieldOverrides[field.id],
        })},[_c('div',{staticClass:"ab-table__cell-content"},[_c(_vm.collectionFieldTypes[field.type].component,_vm._b({tag:"component",attrs:{"element":_vm.element,"field":field,"application-context-additions":{
              recordIndexPath: [
                ..._vm.applicationContext.recordIndexPath,
                rowIndex,
              ],
              field,
            }}},'component',value,false))],1)])]}}])}),_vm._v(" "),_c('div',{staticClass:"table-element__footer"},[(_vm.hasMorePage)?_c('ABButton',{style:(_vm.getStyleOverride('button')),attrs:{"disabled":_vm.contentLoading || !_vm.contentFetchEnabled,"loading":_vm.contentLoading},on:{"click":function($event){return _vm.loadMore()}}},[_vm._v("\n      "+_vm._s(_vm.resolvedButtonLoadMoreLabel || _vm.$t('tableElement.showMore'))+"\n    ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }